import { supabase } from '@/app/config/supabase';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { Box, Button, Divider, List } from '@mui/material';
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { selectUser } from '../auth/slice/selectors';
import { selectSelectedActivityDate } from '../home/tasks/slice/selectors';
import { setSnackbar } from '../ui/slice';
import AddDrawer from './components/add/drawer';
import Link from './components/link';
import { setIsDrawerOpen, setLinks } from './slice';
import { selectLinks } from './slice/selectors';

const Links = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const links = useAppSelector(selectLinks);
  const user = useAppSelector(selectUser);
  const selectedActivityDate = useAppSelector(selectSelectedActivityDate);

  useEffect(() => {
    (async () => {
      if (!user) return;
      try {
        const { data, error } = await supabase
          .from('links')
          .select('id, url')
          .eq('user_id', user?.id)
          .gte(
            'created_at',
            formatISO(startOfDay(parseISO(selectedActivityDate))),
          )
          .lt('created_at', formatISO(endOfDay(parseISO(selectedActivityDate))))
          .order('created_at', { ascending: false });
        if (error) throw new Error(error.message);
        if (data) {
          dispatch(setLinks(data));
        }
      } catch (e) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('general.snackbar.error.message'),
          }),
        );
      }
    })();
  }, [dispatch, selectedActivityDate, t, user]);

  const handleAddDrawerOpen = () => {
    dispatch(setIsDrawerOpen({ key: 'add', value: true }));
  };

  const renderLinks = () => {
    return links?.map((item) => <Link key={item.id} {...item} />);
  };

  return (
    <>
      <Box
        display={'flex'}
        sx={{
          '& hr': {
            mx: 1,
          },
        }}
      >
        <AddDrawer />
        <Button
          sx={{ alignSelf: 'start' }}
          variant={'contained'}
          startIcon={<Icon icon={'add'} />}
          onClick={handleAddDrawerOpen}
        >
          {t('links.add.button.label')}
        </Button>
        <Divider orientation={'vertical'} flexItem />
      </Box>
      <List>{renderLinks()}</List>
    </>
  );
};

export default Links;
