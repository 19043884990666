import Logo from '@/app/components/logo';
import Icon from '@/app/icon';
import { useAppTheme } from '@/app/theme';
import {
  Box,
  Divider,
  IconButton,
  Link,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';
import { useTranslation } from 'react-i18next';

interface Section {
  category: string;
  links: {
    title: string | JSX.Element;
    href: string;
    target?: HTMLAttributeAnchorTarget;
  }[];
}

const Footer = () => {
  const theme = useAppTheme();
  const { t } = useTranslation();

  const sections: Section[] = [
    {
      category: t('footer.product.category'),
      links: [
        {
          title: t('footer.product.links.sign-in'),
          href: '/auth',
        },
        {
          title: t('footer.product.links.roadmap'),
          href: 'https://todochallenge.notion.site/Todochallenge-Roadmap-cec3ca4eb0634ce88a4bdd3a561acf0a',
          target: '_blank',
        },
      ],
    },
    {
      category: t('footer.social-and-support.category'),
      links: [
        {
          title: (
            <IconButton sx={{ p: 1.5 }} color={'primary'}>
              <Icon icon={'x'} size={24} color={'primary'} />
            </IconButton>
          ),
          href: 'https://x.com/@todochallenge',
          target: '_blank',
        },
        {
          title: (
            <IconButton sx={{ p: 1.5 }} color={'primary'}>
              <Icon icon={'mail-outlined'} size={24} color={'primary'} />
            </IconButton>
          ),
          href: 'mailto:hello@todochallenge.app',
          target: '_blank',
        },
      ],
    },
    {
      category: t('footer.legal.category'),
      links: [
        {
          title: t('footer.legal.links.privacy'),
          href: '/policies/privacy',
          target: '_blank',
        },
        {
          title: t('footer.legal.links.terms-and-conditions'),
          href: '/policies/terms-and-conditions',
          target: '_blank',
        },
      ],
    },
  ];

  return (
    <>
      <Divider />
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        p={4}
        borderRadius={theme.shape.borderRadius}
        textAlign={'center'}
        gap={2}
      >
        <Logo
          fontSize={theme.typography.h3.fontSize as number}
          variant={'h3'}
        />
        <Box display="grid" gridTemplateColumns={'repeat(3, 1fr)'} gap={4}>
          {sections.map((section) => (
            <Box
              key={section.category}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              textAlign={'center'}
            >
              <StyledCaption>{section.category}</StyledCaption>
              <Box
                display={'flex'}
                flexDirection={
                  section.category === 'social & support' ? 'row' : 'column'
                }
                gap={1}
              >
                {section.links.map((item) => (
                  <Link
                    key={item.href}
                    href={item.href}
                    target={item.target}
                    typography={'body1'}
                    underline={'hover'}
                  >
                    {item.title}
                  </Link>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

const StyledCaption = styled((props: TypographyProps) => (
  <Typography
    {...props}
    variant={'caption'}
    textTransform={'uppercase'}
    fontWeight={'bold'}
    mb={2}
  />
))({});

export default Footer;
