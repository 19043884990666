import { RootState } from "@/app/redux/store/types";
import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state: RootState) => state.ui;

export const selectSnackbar = createSelector(
  selectSelf,
  (state) => state.snackbar
);

export const selectTheme = createSelector(selectSelf, (state) => state.theme);

