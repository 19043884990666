import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, formatISO, parseISO, startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { setIsHideLegend, setSelectedActivityDate } from '../slice';
import {
  selectIsHideLegend,
  selectSelectedActivityDate,
  selectSelectedTask,
} from '../slice/selectors';
import Activities from './components/activities';
import Legend from './components/activities/legend';
import Header from './components/header';
import Statistics from './components/statistics';
import Tabs from './components/tabs';

const Overview = () => {
  const theme = useTheme();
  const selectedActivityDate = useAppSelector(selectSelectedActivityDate);
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const selectedTask = useAppSelector(selectSelectedTask);
  const isHideLegend = useAppSelector(selectIsHideLegend);

  return (
    <Box marginLeft={isMobile ? 0 : '256px'} height={'100%'} overflow={'auto'}>
      <Header />
      <Statistics />
      <Box display={'flex'} px={4} gap={2} flexDirection={'column'}>
        <Typography variant={'subtitle1'} color={'text.secondary'}>
          {t('tasks.overview.activities.title')}
        </Typography>
        <Box
          px={4}
          py={2}
          borderRadius={theme.shape.borderRadius}
          sx={{
            outlineWidth: 1,
            outlineStyle: 'solid',
            outlineColor: theme.palette.outline.variant,
          }}
        >
          <FormControlLabel
            checked={isHideLegend}
            defaultChecked={isHideLegend}
            control={
              <Checkbox
                onChange={(event) =>
                  dispatch(setIsHideLegend(event.target.checked))
                }
              />
            }
            label={t('tasks.overview.activities.legend.hide.label')}
            labelPlacement="end"
          />
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={2}
            py={2}
          >
            <Box hidden={isHideLegend}>
              <Legend />
            </Box>
            <Activities />
          </Box>
        </Box>
        <Box
          display={'flex'}
          gap={2}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography fontWeight={'bold'}>
            {format(parseISO(selectedActivityDate), 'EEEE d MMMM yyyy')}
          </Typography>
          {selectedTask && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={parseISO(selectedActivityDate)}
                formatDensity="spacious"
                onChange={(date) => {
                  if (!date) return;
                  if (
                    date < startOfDay(new Date(selectedTask.start_date)) ||
                    date > startOfDay(new Date(selectedTask.end_date))
                  )
                    return;
                  dispatch(setSelectedActivityDate(formatISO(date)));
                }}
                minDate={new Date(selectedTask.start_date)}
                maxDate={new Date(selectedTask.end_date)}
              />
            </LocalizationProvider>
          )}
        </Box>
        <Tabs />
      </Box>
    </Box>
  );
};

export default Overview;
