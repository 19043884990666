import Logo from '@/app/components/logo';
import { useAppTheme } from '@/app/theme';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Header from './header';

const None = () => {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      display={'flex'}
      minHeight={'100vh'}
      marginLeft={isMobile ? 0 : '256px'}
      flexDirection={'column'}
    >
      <Header />
      <Box
        display={'flex'}
        flex={1}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
        gap={2}
      >
        <Logo
          fontSize={theme.typography.h5.fontSize as number}
          variant={'h5'}
          text
          vertical
        />
        <Typography variant={'body1'}>{t('home.none.subtitle')}</Typography>
      </Box>
    </Box>
  );
};

export default None;
