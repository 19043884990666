import { Tables } from "@/app/db/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { formatISO, parseISO } from 'date-fns';
import { Activity } from 'react-activity-calendar';
import { State } from './types';
import { calculateActivityLevel } from './utils';

const initialState: State = {
  selectedActivityDate: formatISO(new Date(), { representation: 'date' }),
  isDrawerOpen: {
    add: false,
    edit: false,
  },
};

export const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setIsDrawerOpen(
      state,
      action: PayloadAction<{
        key: keyof State['isDrawerOpen'];
        value: boolean;
      }>,
    ) {
      return {
        ...state,
        isDrawerOpen: {
          ...state.isDrawerOpen,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setTasks(state, action: PayloadAction<State['tasks']>) {
      return {
        ...state,
        tasks: action.payload,
      };
    },
    setSelectedTask(state, action: PayloadAction<State['selectedTask']>) {
      return {
        ...state,
        selectedTask: action.payload,
      };
    },
    initActivities(state, action: PayloadAction<string[]>) {
      const newActivities: Activity[] = [];
      action.payload.forEach((item) => {
        newActivities.push({
          date: formatISO(parseISO(item), {
            representation: 'date',
          }),
          count: 0,
          level: 0,
        });
      });

      return {
        ...state,
        activities: newActivities,
      };
    },
    addActivities(state, action: PayloadAction<string[]>) {
      let prevActivities = state.activities ?? [];

      if (prevActivities) {
        // loop through each new activity
        action.payload.forEach((item) => {
          const prevActivity = prevActivities.find(
            (activity) => activity.date === item,
          );
          if (!prevActivity) {
            // the previous activity doesn't exist so add a new one in the middle
            const secondToLastIndex = prevActivities.length - 1;
            prevActivities = [
              ...prevActivities.slice(0, secondToLastIndex),
              {
                date: item,
                count: 0,
                level: 0,
              },
              ...prevActivities.slice(secondToLastIndex),
            ];
          }
        });
        return {
          ...state,
          activities: prevActivities,
        };
      }
    },
    updateActivity(
      state,
      action: PayloadAction<{
        subTasks: Tables<'sub_tasks'>[];
        date: Activity['date'];
      }>,
    ) {
      const { subTasks, date } = action.payload;
      let activitiesToUpdate = state.activities ? [...state.activities] : [];
      // fix for single day task
      const all = activitiesToUpdate.filter((item) => item.date === date);
      const prevIndex =
        all.length === 2
          ? all.length - 1
          : activitiesToUpdate.findIndex((item) => item.date === date);
      const activityTasks = subTasks
        ? subTasks.filter((item) => item.date === date)
        : [];
      const level = calculateActivityLevel(activityTasks);
      if (prevIndex !== -1) {
        activitiesToUpdate[prevIndex] = {
          ...activitiesToUpdate[prevIndex],
          level,
        };
      } else {
        const secondToLastIndex = activitiesToUpdate.length - 1;
        activitiesToUpdate = [
          ...activitiesToUpdate.slice(0, secondToLastIndex),
          {
            date: date,
            count: 0,
            level,
          },
          ...activitiesToUpdate.slice(secondToLastIndex),
        ];
      }
      return {
        ...state,
        activities: activitiesToUpdate,
      };
    },
    setActivities(state, action: PayloadAction<State['activities']>) {
      state.activities = action.payload;
    },
    clearActivities(state) {
      state.activities = undefined;
    },
    setSelectedActivityDate(
      state,
      action: PayloadAction<State['selectedActivityDate']>,
    ) {
      return {
        ...state,
        selectedActivityDate: formatISO(parseISO(action.payload), {
          representation: 'date',
        }),
      };
    },
    removeTaskById(state, action: PayloadAction<Tables<'tasks'>['id']>) {
      return {
        ...state,
        tasks: state.tasks?.filter((item) => item.id !== action.payload),
      };
    },
    updateTask(state, action: PayloadAction<Tables<'tasks'>>) {
      const updatedTask = action.payload;

      return {
        ...state,
        tasks: state.tasks?.map((task) =>
          task.id === updatedTask.id ? updatedTask : task,
        ),
      };
    },
    setIsHideLegend(state, action: PayloadAction<State['isHideLegend']>) {
      return {
        ...state,
        isHideLegend: action.payload,
      };
    },
  },
});

export const {
  setTasks,
  setSelectedTask,
  initActivities,
  updateActivity,
  addActivities,
  clearActivities,
  setSelectedActivityDate,
  removeTaskById,
  setActivities,
  setIsDrawerOpen,
  updateTask,
  setIsHideLegend,
} = slice.actions;
export default slice.reducer;
