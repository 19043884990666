import { supabase } from '@/app/config/supabase';
import { Tables } from '@/app/db/types';
import { setSnackbar } from '@/app/features/ui/slice';
import { selectTheme } from '@/app/features/ui/slice/selectors';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import { Tooltip, colors } from '@mui/material';
import { format, formatISO, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import ActivityCalendar, { Level } from 'react-activity-calendar';
import { useTranslation } from 'react-i18next';
import {
  addActivities,
  initActivities,
  setActivities,
  setSelectedActivityDate,
} from '../../../slice';
import {
  selectActivities,
  selectSelectedActivityDate,
  selectSelectedTask,
} from '../../../slice/selectors';

const Activities = () => {
  const activities = useAppSelector(selectActivities);
  const dispatch = useAppDispatch();
  const task = useAppSelector(selectSelectedTask);
  const { t } = useTranslation();
  const [subTasks, setSubTasks] = useState<Tables<'sub_tasks'>[]>();
  const theme = useAppSelector(selectTheme);
  const selectedActivityDate = useAppSelector(selectSelectedActivityDate);
  const appTheme = useAppTheme();
  const todayDate = formatISO(new Date(), { representation: 'date' });

  const getSubTasks = useCallback(async () => {
    if (task) {
      try {
        const { data, error } = await supabase
          .from('sub_tasks')
          .select('*')
          .eq('task_id', task.id);
        if (error) throw new Error(error.message);
        const newActivities: string[] = [];
        data.forEach((item) => {
          newActivities.push(item.date);
        });
        dispatch(addActivities(newActivities));
        return data;
      } catch (_) {
        dispatch(
          setSnackbar({
            message: t('general.snackbar.error.message'),
            severity: 'error',
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We set the first and last date for the activity to show all squares
  useEffect(() => {
    (async () => {
      if (!task) return;
      // eslint-disable-next-line
      // const activities = populateActivities(
      //   task.start_date,
      //   task.end_date,
      // );
      // dispatch(setActivities(activities));
      dispatch(initActivities([task?.start_date, task?.end_date]));
      const result = await getSubTasks();
      if (result) {
        setSubTasks(result);
      }
    })();
  }, [task, dispatch, getSubTasks]);

  useEffect(() => {
    const populateActivities = () => {
      const updatedActivities = activities?.map((activity) => {
        const activityTasks = subTasks?.filter(
          (item) => item.date === activity.date,
        );

        const allTasksCompleted =
          Boolean(activityTasks?.length) &&
          activityTasks?.every((task) => task.done);

        if (allTasksCompleted) {
          return { ...activity, level: 2 as Level };
        }

        const someTasksCompleted =
          Boolean(activityTasks?.length) &&
          activityTasks?.some((task) => !task.done);

        if (someTasksCompleted) {
          return { ...activity, level: 1 as Level };
        }

        return activity;
      });

      dispatch(setActivities(updatedActivities));
    };

    if (subTasks) {
      populateActivities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subTasks]);

  useEffect(() => {
    const updateScrollContainerStyle = () => {
      const scrollContainer = document.querySelector(
        '.react-activity-calendar__scroll-container',
      );

      if (scrollContainer instanceof HTMLElement) {
        scrollContainer.style.padding = '8px';
        scrollContainer.style.paddingTop = '0px';
      }
    };
    updateScrollContainerStyle();
  }, [activities]);

  useEffect(() => {
    dispatch(setSelectedActivityDate(todayDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!activities) return <></>;

  return (
    <ActivityCalendar
      data={activities}
      colorScheme={theme}
      hideTotalCount
      maxLevel={2}
      theme={{
        light: [colors.grey[300], colors.red[300], colors.lightGreen[300]],
        dark: [colors.grey[500], colors.red[300], colors.lightGreen[300]],
      }}
      eventHandlers={{
        onClick: () => (activity) => {
          dispatch(setSelectedActivityDate(activity.date));
        },
      }}
      hideColorLegend
      blockSize={16}
      showWeekdayLabels
      renderBlock={(block, activity) => {
        const activityDate = activity.date;
        const isSelected = selectedActivityDate === activityDate;
        const isToday = activityDate === todayDate;
        return (
          <>
            {/* shape */}
            {React.cloneElement(block, {
              style: {
                clipPath:
                  activity.level === 1
                    ? 'polygon(0 100%, 0 0, 100% 100%)'
                    : 'none',
              },
              rx: isToday && !isSelected ? 100 : 2,
              ry: isToday && !isSelected ? 100 : 2,
            })}
            <Tooltip
              title={format(parseISO(activity.date), 'EEEE d MMMM yyyy')}
              disableInteractive
            >
              {/* stroke */}
              {React.cloneElement(block, {
                style: {
                  fill: 'none',
                  stroke: appTheme.palette.text.primary,
                  strokeWidth: isSelected || isToday ? 2 : 0,
                },
                rx: isToday && !isSelected ? 100 : 2,
                ry: isToday && !isSelected ? 100 : 2,
                pointerEvents: 'visible',
              })}
            </Tooltip>
          </>
        );
      }}
      labels={{
        weekdays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      }}
    />
  );
};

export default Activities;
