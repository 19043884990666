import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import Footer from "../../footer";
import Header from "../../menu";

const Page = () => {
  const items = [
    {
      title: 'Introduction',
      description:
        'Welcome to Todochallenge ("we," "us," or "our"). At Todochallenge, we are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, and disclose information through our website and services ("Services").',
    },
    {
      title: 'By using our Services',
      description:
        'By using our Services, you agree to the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our Services.',
    },
    {
      title: 'Information We Collect',
      description:
        'We collect certain personal information about you when you visit our website or interact with our product. This may include your name, email address, contact information, payment details, and any other information you voluntarily provide to us. We prioritize the security and confidentiality of your data and use it solely for the purpose of enhancing your experience with our services. Your trust is important to us, and we are committed to safeguarding the privacy of the information you share.',
    },
    {
      title: 'How We Use Your Information',
      description:
        'We use the information we collect for various purposes, including to:\n\nProvide and improve our Services.\nCommunicate with you about your account and tasks.\nRespond to your inquiries and provide customer support.\nEnsure the security and integrity of our Services.\nComply with legal and regulatory requirements.',
    },
    {
      title: 'Sharing Your Information',
      description:
        'We may share your information in the following circumstances:\n\nWith your consent.\nTo comply with legal obligations.\nTo protect our rights and interests.\nWith service providers who assist us in providing the Services.\nIn connection with a merger, acquisition, or sale of assets.',
    },
    {
      title: 'Security',
      description:
        'We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your information.',
    },
    {
      title: 'Changes to this Privacy Policy',
      description:
        'We may update this Privacy Policy from time to time. If we make material changes, we will notify you by posting the updated Privacy Policy on our website or through other communication channels.',
    },
    {
      title: 'Contact Us',
      description: (
        <span>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{' '}
          <Link href="mailto:hello@todochallenge.app" target="_blank">
            hello@todochallenge.app
          </Link>
          .
        </span>
      ),
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box display={"flex"} gap={2} flexDirection={"column"} py={2}>
        <Header />
        <Box
          display={"flex"}
          gap={2}
          flexDirection={"column"}
          textAlign={"center"}
        >
          <Typography variant={"h3"} fontWeight={"bold"}>
            Privacy Policy
          </Typography>
          <Typography variant={"h4"}>
            <span style={{ fontWeight: "bold" }}>Last Updated:</span> 13th
            December 2023
          </Typography>
          <Typography variant={"body1"}>
            {`Welcome to Todochallenge ("we," "us," or "our"). Please read this Privacy Policy carefully before using our website and services ("Services"). By accessing or using Todochallenge, you agree to be bound by this Privacy Policy.`}
          </Typography>
        </Box>
        <List>
          {items.map((item, index) => (
            <ListItem
              key={item.title}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <ListItemText
                primary={`${index + 1}. ${item.title}`}
                secondary={item.description}
                primaryTypographyProps={{
                  fontWeight: "bold",
                }}
              />
            </ListItem>
          ))}
        </List>
        <Footer />
      </Box>
    </Container>
  );
};

export default Page;
