import Icon from '@/app/icon';
import { useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import {
  Badge,
  Box,
  CardContent,
  Container,
  Paper,
  PaperProps,
  Typography,
  colors,
  styled,
} from '@mui/material';
import Color from 'color';
import { useTranslation } from 'react-i18next';
import { selectTheme } from '../../ui/slice/selectors';

const Steps = () => {
  const appTheme = useAppTheme();
  const theme = useAppSelector(selectTheme);
  const { t } = useTranslation('translation', { keyPrefix: 'steps' });

  const items = [
    {
      iconName: 'plus-square',
      title: t('add-task.title'),
      subtitle: t('add-task.subtitle'),
    },
    {
      iconName: 'calender',
      title: t('set-deadline.title'),
      subtitle: t('set-deadline.subtitle'),
    },
    {
      iconName: 'plus-square',
      title: t('add-sub-tasks.title'),
      subtitle: t('add-sub-tasks.subtitle'),
    },
    {
      iconName: 'check-square',
      title: t('do-sub-tasks.title'),
      subtitle: t('do-sub-tasks.subtitle'),
    },
    {
      iconName: 'smile',
      title: t('congratulations.title'),
      subtitle: t('congratulations.subtitle'),
    },
    {
      iconName: 'archive',
      title: t('archive-task.title'),
      subtitle: t('archive-task.subtitle'),
    },
  ];

  return (
    <Paper
      sx={{
        display: 'flex',
        boxShadow: 'none',
        bgcolor: theme === 'light' ? colors.grey[100] : '',
        borderRadius: 0,
        py: 8,
        textAlign: 'center',
      }}
    >
      <Container maxWidth={'md'}>
        <Box
          display={'flex'}
          textAlign={'center'}
          gap={2}
          flexDirection={'column'}
        >
          <Typography
            variant={'h4'}
            fontWeight={appTheme.typography.fontWeightBold}
          >
            {t('title')}
          </Typography>
          <Typography variant={'body1'} fontWeight={'bold'}>
            {t('subtitle')}
          </Typography>
        </Box>
        <Box
          display={'grid'}
          gridTemplateColumns={{ sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
          gap={2}
        >
          {items.map(({ iconName, subtitle, title }, index) => (
            <StyledPaper theme={appTheme} key={title}>
              <StyledCardContent>
                <Box
                  p={1}
                  sx={{
                    outlineWidth: 1,
                    outlineColor: appTheme.palette.primary.main,
                    outlineStyle: 'solid',
                  }}
                  borderRadius={appTheme.shape.borderRadius}
                  position={'relative'}
                >
                  <Icon
                    icon={iconName}
                    size={32}
                    color={appTheme.palette.primary.main}
                  />
                  <Badge
                    sx={{
                      position: 'absolute',
                      bottom: 4,
                      right: 4,
                      '& .MuiBadge-dot': {
                        backgroundColor: 'transparent', // Set dot background to transparent
                      },
                      '& .MuiBadge-badge': {
                        backgroundColor: 'transparent', // Set badge background to transparent
                        backgroundImage: `linear-gradient(45deg, ${colors.red[300]}, ${colors.lightGreen[300]})`,
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: Color(
                            appTheme.palette.secondary.main,
                          )
                            .alpha(0.2)
                            .toString(),
                          zIndex: -1,
                          borderRadius: 50,
                        },
                      },
                    }}
                    badgeContent={index + 1}
                  />
                </Box>
                <Box>
                  <Typography
                    variant={'h6'}
                    fontWeight={appTheme.typography.fontWeightBold}
                  >
                    {title}
                  </Typography>
                  <Typography component={'p'}>{subtitle}</Typography>
                </Box>
              </StyledCardContent>
            </StyledPaper>
          ))}
        </Box>
      </Container>
    </Paper>
  );
};

const StyledCardContent = styled(CardContent)({
  gap: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledPaper = styled((props: PaperProps) => (
  <Paper {...props} elevation={0} />
))(({ theme }) => ({
  flex: 1,
  borderRadius: theme.shape.borderRadius * 4,
  background: 'transparent',
}));

export default Steps;
