import { supabase } from '@/app/config/supabase';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { Box, Button, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setSnackbar } from '../../ui/slice';
import {
  selectSelectedActivityDate,
  selectSelectedTask,
} from '../tasks/slice/selectors';
import AddDrawer from './components/add/drawer';
import EditDrawer from './components/edit/drawer';
import SubTask from './components/sub-task';
import { setIsDrawerOpen, setSubTasks } from './slice';
import { selectSubTasks } from './slice/selectors';

const SubTasks = () => {
  const { t } = useTranslation();
  const selectedTask = useAppSelector(selectSelectedTask);
  const dispatch = useAppDispatch();
  const subTasks = useAppSelector(selectSubTasks);
  const activityDate = useAppSelector(selectSelectedActivityDate);

  useEffect(() => {
    (async () => {
      if (!selectedTask) return;
      try {
        const { data, error } = await supabase
          .from('sub_tasks')
          .select('*')
          .eq('task_id', selectedTask.id)
          .eq('date', activityDate)
          .order('date', { ascending: false });
        if (error) throw new Error(error.message);
        if (data) {
          dispatch(setSubTasks(data));
        }
      } catch (e) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('general.snackbar.error.message'),
          }),
        );
      }
    })();
  }, [dispatch, selectedTask, activityDate, t]);

  const doCopyToClipboard = async () => {
    if (!subTasks) return;
    const titles: string[] = subTasks
      .filter((item) => item.done)
      .map((item) => `✅ ${item.title}`);
    const titlesText: string = titles.join('\n');
    try {
      await navigator.clipboard.writeText(titlesText);
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t(
            'tasks.overview.copy-to-clipboard.success.snackbar.message',
          ),
        }),
      );
    } catch (e) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: t('general.snackbar.error.message'),
        }),
      );
    }
  };

  const handleAddDrawerOpen = () => {
    dispatch(setIsDrawerOpen({ key: 'add', value: true }));
  };

  const renderTasks = () => {
    return subTasks?.map((item) => <SubTask key={item.id} {...item} />);
  };

  return (
    <>
      <AddDrawer key={activityDate} />
      <EditDrawer />
      <Box
        display={'flex'}
        sx={{
          '& hr': {
            mx: 1,
          },
        }}
      >
        <Button
          sx={{ alignSelf: 'start' }}
          variant={'contained'}
          startIcon={<Icon icon={'add'} />}
          onClick={handleAddDrawerOpen}
        >
          {t('sub-tasks.add.button.label')}
        </Button>
        <Divider orientation={'vertical'} flexItem />
        <Button variant={'outlined'} onClick={doCopyToClipboard}>
          {t('tasks.overview.copy-to-clipboard.button.label')}
        </Button>
      </Box>
      {renderTasks()}
    </>
  );
};

export default SubTasks;
