import Logo from '@/app/components/logo';
import { analytics } from '@/app/config/firebase';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import { Events } from '@/app/utils/firebase/enums';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setTheme } from '../../ui/slice';
import { selectTheme } from '../../ui/slice/selectors';

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useAppSelector(selectTheme);
  const dispatch = useAppDispatch();
  const appTheme = useAppTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down('md'));

  const handleTheme = () => {
    theme === 'light' && dispatch(setTheme('dark'));
    theme === 'dark' && dispatch(setTheme('light'));
  };

  return (
    <AppBar
      position={'static'}
      color={'transparent'}
      sx={{ boxShadow: 'none' }}
    >
      <Toolbar
        sx={{
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        disableGutters
      >
        <Logo
          variant={'h5'}
          fontSize={appTheme.typography.h5.fontSize as number}
          text={!isMobile}
        />
        <Box display={'flex'} gap={1}>
          <IconButton
            onClick={handleTheme}
            sx={{
              outlineColor: appTheme.palette.outline.main,
              outlineWidth: 1,
              outlineStyle: 'solid',
            }}
          >
            <Icon icon={theme === 'light' ? 'moon' : 'sun'} />
          </IconButton>
          <Button
            variant={'outlined'}
            onClick={() => {
              if (process.env.NODE_ENV !== 'development') {
                logEvent(analytics, Events.ROOT_ROADMAP);
              }
              window.open(
                'https://todochallenge.notion.site/Todochallenge-Roadmap-cec3ca4eb0634ce88a4bdd3a561acf0a',
                '_blank',
              );
            }}
          >
            {t('menu.roadmap.button.label')}
          </Button>
          <Button
            variant={'contained'}
            disableElevation
            onClick={() => {
              if (process.env.NODE_ENV !== 'development') {
                logEvent(analytics, Events.ROOT_SIGN_IN);
              }
              navigate('/auth');
            }}
          >
            {t('menu.sign-in.button.label')}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
