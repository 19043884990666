import { useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectActivities } from '../../../slice/selectors';
import TaskCompletionCard from './task-completion';
import TimelineCompletionCard from './timeline-completion';

const Statistics = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.overview.statistics',
  });
  const activities = useAppSelector(selectActivities);
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!activities) return <></>;

  return (
    <Box display={'flex'} my={2} px={4} gap={2} flexDirection={'column'}>
      <Typography variant={'subtitle1'} color={'text.secondary'}>
        {t('title')}
      </Typography>
      <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} gap={2}>
        <Box display={'flex'}>
          <TimelineCompletionCard />
        </Box>
        <Box display={'flex'}>
          <TaskCompletionCard />
        </Box>
      </Box>
    </Box>
  );
};

export default Statistics;
