import { colors } from '@mui/material';
import { PaletteOptions, createTheme, useTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    displayLarge: React.CSSProperties;
    displaySmall: React.CSSProperties;
    headlineSmall: React.CSSProperties;
    titleSmall: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    displayLarge?: React.CSSProperties;
    displaySmall?: React.CSSProperties;
    headlineSmall?: React.CSSProperties;
    titleSmall?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    displayLarge: true;
    displaySmall: true;
    headlineSmall: true;
    titleSmall: true;
  }
}

declare module '@mui/material/styles' {
  interface PaletteOptions {
    primary?: PaletteColorOptions;
    outline: PaletteColorOptions;
  }
  interface Palette {
    primary: PaletteColor;
    outline: PaletteColor;
  }
  interface PaletteColor {
    main: string;
    variant: string;
  }
  interface PaletteColorOptions {
    main: React.CSSProperties['color'];
    variant?: React.CSSProperties['color'];
  }
}

const createBaseTheme = (palette: PaletteOptions) => {
  return createTheme({
    palette,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'initial',
            borderRadius: 50,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: 50,
          },
        },
      },
    },
    shape: {
      borderRadius: 4,
    },
    typography: {
      displayLarge: {
        fontSize: '57px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '64px',
      },
      displaySmall: {
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '44px',
      },
      headlineSmall: {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '32px',
      },
      titleSmall: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
      },
    },
  });
};

export const lightTheme = createBaseTheme({
  mode: 'light',
  primary: { main: colors['grey'][900] },
  secondary: { main: colors['grey'][300] },
  outline: { main: '#7A757F', variant: '#CAC4CF' },
  background: { default: colors['grey'][50], paper: colors['grey'][50] },
});

export const darkTheme = createBaseTheme({
  mode: 'dark',
  primary: { main: colors['grey'][300] },
  secondary: { main: colors['grey'][900] },
  outline: { main: '#7A757F', variant: '#CAC4CF' },
  text: { primary: colors['grey'][300] },
});

export type AppTheme = typeof lightTheme | typeof darkTheme;
export const useAppTheme = useTheme<AppTheme>;
