import { RootState } from "@/app/redux/store/types";
import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state: RootState) => state.tasks;

export const selectIsDrawerOpen = (key: keyof RootState['tasks']['isDrawerOpen'] ) => createSelector(
  selectSelf,
  (state) => state.isDrawerOpen[key]
);

export const selectTasks = createSelector(selectSelf, (state) => state.tasks);

export const selectSelectedTask = createSelector(
  selectSelf,
  (state) => state.selectedTask,
);

export const selectActivities = createSelector(
  selectSelf,
  (state) => state.activities,
);

export const selectSelectedActivityDate = createSelector(
  selectSelf,
  (state) => state.selectedActivityDate,
);

export const selectIsHideLegend = createSelector(
  selectSelf,
  (state) => state.isHideLegend,
);
