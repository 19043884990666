import { RootState } from '@/app/redux/store/types';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state['sub-tasks'];

export const selectIsDrawerOpen = (
  key: keyof RootState['sub-tasks']['isDrawerOpen'],
) => createSelector(selectSelf, (state) => state.isDrawerOpen[key]);

export const selectSubTasks = createSelector(
  selectSelf,
  (state) => state.subTasks,
);

export const selectSelectedSubTask = createSelector(
  selectSelf,
  (state) => state.selectedSubTask,
);
