import { Enums, Tables } from '@/app/db/types';
import { Activity } from 'react-activity-calendar';

export interface State {
  isDrawerOpen: {
    add: boolean;
    edit: boolean;
  };
  tasks?: Tables<'tasks'>[];
  selectedTask?: Tables<'tasks'>;
  activities?: Activity[];
  selectedActivityDate: string;
  isHideLegend?: boolean;
}

export enum Interval {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  RANGE = 'range',
}

export interface FormValues extends Partial<Tables<'tasks'>> {
  name: string;
  startDate: Date;
  endDate: Date;
  interval: Enums<'task_interval'>;
}
