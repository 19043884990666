import { Tables } from '@/app/db/types';
import { endOfDay, parse, startOfDay } from 'date-fns';
import { Activity } from 'react-activity-calendar';

export function calculateTaskCompletionPercentage(activities: Activity[]) {
  const doneCount = activities.filter(
    (activity) => activity.level === 2,
  ).length;
  const incompleteCount = activities.filter(
    (activity) => activity.level === 1,
  ).length;
  const totalCount = doneCount + incompleteCount;
  if (totalCount === 0) return undefined;
  const percentage = (doneCount / totalCount) * 100;
  const wholePercentage = Math.floor(percentage);
  return wholePercentage;
}

export function calculateTimelineCompletionPercentage(
  startDate: Tables<'tasks'>['start_date'],
  endDate: Tables<'tasks'>['end_date'],
) {
  const todayDate = new Date();
  const parsedStartDate = startOfDay(parse(startDate, 'yyyy-MM-dd', todayDate));
  const parsedEndDate = endOfDay(parse(endDate, 'yyyy-MM-dd', todayDate));

  const percentage =
    ((todayDate.getTime() - parsedStartDate.getTime()) /
      (parsedEndDate.getTime() - parsedStartDate.getTime())) *
    100;
  const wholePercentage = Math.floor(percentage);
  return wholePercentage;
}
