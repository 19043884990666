import Icon from "@/app/icon";
import { useAppTheme } from "@/app/theme";
import { Box, Container, IconButton, Link, Typography } from "@mui/material";
import { useRef } from "react";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";

const Page = () => {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  return (
    <Container maxWidth={"lg"}>
      <Confetti
        width={windowWidth.current}
        height={windowHeight.current}
        style={{ zIndex: -1 }}
      />
      <Box
        height={"100vh"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          textAlign={"center"}
          gap={2}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Icon
            icon={"check-circle"}
            size={128}
            color={theme.palette.success.light}
          />
          <Typography variant={"h2"} fontWeight={"bold"}>
            {t("verify.title")}
          </Typography>
          <Typography
            variant={"h5"}
            sx={{ color: theme.palette.primary.main, opacity: 0.38 }}
          >
            {t("verify.subtitle")}
          </Typography>
          <Box mt={2} textAlign={"center"}>
            <Typography
              variant={"overline"}
              sx={{ color: theme.palette.primary.main, opacity: 0.38 }}
            >
              {t("joined.communities-caption")}
            </Typography>
            <Box display={"flex"} gap={1} justifyContent={"center"}>
              <Link href="https://x.com/todochallenge" target="_blank">
                <IconButton sx={{ p: 2 }}>
                  <Icon
                    icon={"x"}
                    color={theme.palette.primary.main}
                    size={32}
                  />
                </IconButton>
              </Link>
              <Link href="https://discord.gg/57zfvyw24M" target="_blank">
                <IconButton sx={{ p: 2 }}>
                  <Icon
                    icon={"discord"}
                    color={theme.palette.primary.main}
                    size={32}
                  />
                </IconButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Page;
