import { normaliseKey } from '@/app/utils/i18n';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormValues, Interval } from '../../slice/types';

const IntervalInput = () => {
  const { control } = useFormContext<FormValues>();

  const keys = Object.keys(Interval) as (keyof typeof Interval)[];
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.form.interval',
  });

  return (
    <FormControl>
      <InputLabel>{t('label')}</InputLabel>
      <Controller
        control={control}
        render={({ field }) => (
          <Select {...field} label={t('label')}>
            {keys.map((key) => (
              <MenuItem key={key} value={Interval[key]}>
                {t(normaliseKey(`values.${Interval[key]}`))}
              </MenuItem>
            ))}
          </Select>
        )}
        name={'interval'}
      />
    </FormControl>
  );
};

export default IntervalInput;
