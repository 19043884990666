import { supabase } from "@/app/config/supabase";
import { selectUser } from "@/app/features/auth/slice/selectors";
import { setTheme } from '@/app/features/ui/slice';
import { selectTheme } from '@/app/features/ui/slice/selectors';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
  MenuList,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Menu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const theme = useAppSelector(selectTheme);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    supabase.auth.signOut();
  };

  const handleTheme = () => {
    theme === 'light' && dispatch(setTheme('dark'));
    theme === 'dark' && dispatch(setTheme('light'));
  };

  if (!user) return <></>;

  return (
    <>
      <ListItem>
        <ListItemButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ListItemAvatar>
            <Avatar src={user.user_metadata['avatar_url']} />
          </ListItemAvatar>
          <ListItemText
            primary={t('drawer.header.title')}
            secondary={user.user_metadata['full_name']}
          />
        </ListItemButton>
      </ListItem>
      <MUIMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList>
          <MUIMenuItem onClick={handleTheme}>
            <ListItemIcon>
              <Icon icon={theme === 'light' ? 'moon' : 'sun'} />
            </ListItemIcon>
            <ListItemText>
              {t('drawer.menu.change-theme.button.label')}
            </ListItemText>
          </MUIMenuItem>
          <MUIMenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Icon icon={'logout'} />
            </ListItemIcon>
            <ListItemText>{t('drawer.menu.logout.button.label')}</ListItemText>
          </MUIMenuItem>
        </MenuList>
      </MUIMenu>
    </>
  );
};

export default Menu;
