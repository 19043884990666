import { supabase } from '@/app/config/supabase';
import { selectUser } from '@/app/features/auth/slice/selectors';
import { setSnackbar } from '@/app/features/ui/slice';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  FormHelperText,
  IconButton,
  ListItem,
  Drawer as MUIDrawer,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, addMonths, addWeeks, sub } from 'date-fns';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DurationInput from '../../components/form/duration-input';
import IntervalInput from '../../components/form/interval-input';
import { setIsDrawerOpen, setSelectedTask, updateTask } from '../../slice';
import { selectIsDrawerOpen, selectSelectedTask } from '../../slice/selectors';
import { FormValues, Interval } from '../../slice/types';
import { validateEndDate } from '../../validate';

const Drawer = () => {
  const isOpen = useAppSelector(selectIsDrawerOpen('edit'));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const user = useAppSelector(selectUser);
  const selectedTask = useAppSelector(selectSelectedTask);
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const methods = useForm<FormValues>({
    defaultValues: {
      ...selectedTask,
      startDate: new Date(selectedTask?.start_date ?? ''),
      endDate: new Date(selectedTask?.end_date ?? ''),
    },
    mode: 'all',
  });
  const {
    handleSubmit,
    formState: { isValid, errors },
    reset,
    control,
    getValues,
    watch,
  } = methods;
  const interval = watch('interval');

  const handleOnClose = () => {
    dispatch(setIsDrawerOpen({ key: 'edit', value: false }));
    reset();
  };

  const handleOnSubmit = async ({
    name,
    startDate,
    endDate,
    interval,
    duration,
  }: FormValues) => {
    if (!user || !selectedTask) return;
    setIsLoading(true);
    if (duration) {
      switch (interval) {
        case Interval.DAYS: {
          endDate = sub(addDays(startDate, duration), { days: 1 });
          break;
        }
        case Interval.WEEKS: {
          endDate = sub(addWeeks(startDate, duration), { days: 1 });
          break;
        }
        case Interval.MONTHS: {
          endDate = sub(addMonths(startDate, duration), { days: 1 });
          break;
        }
      }
    }

    try {
      const { data, error } = await supabase
        .from('tasks')
        .update({
          name,
          user_id: user.id,
          end_date: endDate.toISOString(),
          duration,
          interval,
        })
        .match({ id: selectedTask.id })
        .select('*')
        .single();
      if (error) throw new Error(error.message);
      dispatch(updateTask(data));
      dispatch(setIsDrawerOpen({ key: 'edit', value: false }));
      reset();
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t('tasks.edit.success.snackbar.message'),
        }),
      );
      dispatch(setSelectedTask(data));
    } catch (e) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: t('general.snackbar.error.message'),
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!selectedTask) return <></>;

  return (
    <FormProvider {...methods}>
      <MUIDrawer
        variant={'temporary'}
        open={isOpen}
        anchor={'right'}
        onClose={handleOnClose}
        PaperProps={{
          sx: {
            maxWidth: isMobile ? '100%' : 300,
          },
        }}
      >
        <AppBar
          position={'static'}
          sx={{
            bgcolor: 'transparent',
            color: theme.palette.text.primary,
            pl: 0,
          }}
          elevation={0}
        >
          <Toolbar sx={{ gap: 2 }}>
            <IconButton onClick={handleOnClose}>
              <Icon icon={'close'} />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {t('tasks.edit.title')}
            </Typography>
          </Toolbar>
        </AppBar>
        <ListItem>
          <Box
            component={'form'}
            display={'flex'}
            flexDirection={'column'}
            flex={1}
            gap={2}
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <Controller
              control={control}
              name={'name'}
              rules={{ required: t('tasks.form.name.error.required') }}
              render={({ field }) => (
                <TextField {...field} label={t('tasks.form.name.label')} />
              )}
            />
            <FormHelperText
              error
              sx={{
                maxWidth: 256,
              }}
            >
              {errors.name?.message}
            </FormHelperText>
            <Typography variant={'subtitle1'} color={'text.secondary'}>
              {t('tasks.subtitle.deadline')}
            </Typography>
            <IntervalInput />
            {interval === Interval.RANGE ? (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled
                    value={new Date(selectedTask?.start_date)}
                    label={t('tasks.form.start-date.label')}
                  />
                  <FormHelperText />
                </LocalizationProvider>
                <Controller
                  control={control}
                  name={'endDate'}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        label={t('tasks.form.end-date.label')}
                      />
                      <FormHelperText
                        error
                        sx={{
                          maxWidth: 256,
                        }}
                      >
                        {errors.endDate?.message}
                      </FormHelperText>
                    </LocalizationProvider>
                  )}
                  rules={{
                    validate: () =>
                      validateEndDate(
                        new Date(selectedTask.start_date),
                        getValues('endDate'),
                      ),
                  }}
                />
              </>
            ) : (
              <DurationInput />
            )}
            <LoadingButton
              variant={'contained'}
              type={'submit'}
              disabled={!isValid}
              loading={isLoading}
            >
              {t('tasks.done.button.label')}
            </LoadingButton>
          </Box>
        </ListItem>
      </MUIDrawer>
    </FormProvider>
  );
};

export default Drawer;
