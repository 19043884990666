import { supabase } from '@/app/config/supabase';
import { Tables } from '@/app/db/types';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { Checkbox, IconButton, ListItem, ListItemText } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSnackbar } from '../../../ui/slice';
import { updateActivity } from '../../tasks/slice';
import {
  removeSubTaskById,
  setIsDrawerOpen,
  setSelectedSubTask,
  setSubTasks,
} from '../slice';
import { selectSubTasks } from '../slice/selectors';

const SubTask = ({
  title,
  description,
  done: defaultDone,
  id,
  date,
  ...rest
}: Tables<'sub_tasks'>) => {
  const [done, setDone] = useState(defaultDone);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const subTasks = useAppSelector(selectSubTasks);

  const handleCheckboxClick = async () => {
    if (!subTasks) return;
    const newValue = !done;
    setDone(newValue);
    try {
      const { data, error } = await supabase
        .from('sub_tasks')
        .update({ done: newValue })
        .eq('id', id)
        .select()
        .single();
      if (error) throw new Error(error.message);
      const newSubTasks = subTasks?.map((item) =>
        item.id === data.id ? data : item,
      );
      dispatch(updateActivity({ subTasks: newSubTasks, date: data.date }));
      dispatch(setSubTasks(newSubTasks));
    } catch (e) {
      dispatch(
        setSnackbar({
          message: t('general.snackbar.error.message'),
          severity: 'error',
        }),
      );
      const oldValue = done;
      setDone(!oldValue);
    }
  };

  const handleOnMouseOver = () => {
    setIsHover(true);
  };

  const handleOnMouseLeave = () => {
    setIsHover(false);
  };

  const handleEdit = () => {
    dispatch(
      setSelectedSubTask({
        title,
        description,
        done: defaultDone,
        id,
        date,
        ...rest,
      }),
    );
    dispatch(setIsDrawerOpen({ key: 'edit', value: true }));
  };

  const handleDelete = async () => {
    if (!subTasks) return;
    try {
      const { data, error } = await supabase
        .from('sub_tasks')
        .delete()
        .eq('id', id)
        .select('*')
        .single();
      if (error) throw new Error(error.message);
      dispatch(removeSubTaskById(id));
      const newSubTasks = subTasks?.filter((item) => item.id !== data.id);
      dispatch(updateActivity({ subTasks: newSubTasks, date: data.date }));
      dispatch(
        setSnackbar({
          message: t('sub-tasks.delete.success.snackbar.message'),
          severity: 'success',
        }),
      );
    } catch (_) {
      dispatch(
        setSnackbar({
          message: t('general.snackbar.error.message'),
          severity: 'error',
        }),
      );
    }
  };

  return (
    <ListItem
      disableGutters
      divider
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
    >
      <Checkbox checked={done} onClick={handleCheckboxClick} />
      <ListItemText
        primary={title}
        secondary={description}
        sx={{
          textDecoration: done ? 'line-through' : '',
          wordWrap: 'break-word',
        }}
      />
      {isHover && (
        <>
          <IconButton onClick={handleEdit}>
            <Icon icon={'edit-outlined'} />
          </IconButton>
          <IconButton
            color={'error'}
            TouchRippleProps={{
              color: 'error',
            }}
            onClick={handleDelete}
          >
            <Icon icon={'trash'} />
          </IconButton>
        </>
      )}
    </ListItem>
  );
};

export default SubTask;
