import Links from '@/app/features/links';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SubTasks from '../../../sub-tasks';

export default function Tabs() {
  const [value, setValue] = React.useState('1');
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.overview.tabs',
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label={t('sub-tasks.label')} value="1" />
          <Tab label={t('links.label')} value="2" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <SubTasks />
      </TabPanel>
      <TabPanel value="2">
        <Links />
      </TabPanel>
    </TabContext>
  );
}
