import { setIsDrawerOpen, setLinks } from '@/app/features/links/slice';
import {
  selectIsDrawerOpen,
  selectLinks,
} from '@/app/features/links/slice/selectors';

import { supabase } from '@/app/config/supabase';
import { Tables } from '@/app/db/types';
import { selectUser } from '@/app/features/auth/slice/selectors';
import { selectSelectedActivityDate } from '@/app/features/home/tasks/slice/selectors';
import { setSnackbar } from '@/app/features/ui/slice';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  FormHelperText,
  IconButton,
  ListItem,
  Drawer as MUIDrawer,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { formatISO, parseISO } from 'date-fns';
import { t } from 'i18next';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const Drawer = () => {
  const isOpen = useAppSelector(selectIsDrawerOpen('add'));
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const user = useAppSelector(selectUser);
  const links = useAppSelector(selectLinks);
  const selectedActivityDate = useAppSelector(selectSelectedActivityDate);
  const {
    handleSubmit,
    formState: { isValid, errors },
    reset,
    control,
  } = useForm<Tables<'links'>>({
    defaultValues: {
      url: '',
    },
    mode: 'all',
  });

  const handleOnClose = () => {
    dispatch(setIsDrawerOpen({ key: 'add', value: false }));
    reset();
  };

  const handleOnSubmit = async ({ url }: Tables<'links'>) => {
    if (!user) return;
    setIsLoading(true);
    const today = new Date();
    try {
      const { data, error } = await supabase
        .from('links')
        .insert({
          url,
          user_id: user.id,
          created_at: formatISO(
            parseISO(selectedActivityDate).setHours(
              today.getHours(),
              today.getMinutes(),
              today.getSeconds(),
            ),
          ),
        })
        .select('id, url')
        .single();
      if (error) throw new Error(error.message);
      if (data) {
        dispatch(setLinks(links ? [data, ...links] : [data]));
      }
      dispatch(setIsDrawerOpen({ key: 'add', value: false }));
      reset();
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t('links.add.success.message'),
        }),
      );
    } catch (e) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: t('general.snackbar.error.message'),
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MUIDrawer
      variant={'temporary'}
      open={isOpen}
      anchor={'right'}
      onClose={handleOnClose}
      PaperProps={{
        sx: {
          minWidth: isMobile ? '100%' : 300,
        },
      }}
    >
      <AppBar
        position={'static'}
        sx={{
          bgcolor: 'transparent',
          color: theme.palette.text.primary,
          pl: 0,
        }}
        elevation={0}
      >
        <Toolbar sx={{ gap: 2 }}>
          <IconButton onClick={handleOnClose}>
            <Icon icon={'close'} />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {t('links.add.title')}
          </Typography>
        </Toolbar>
      </AppBar>
      <ListItem>
        <Box
          component={'form'}
          display={'flex'}
          flexDirection={'column'}
          flex={1}
          gap={2}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <Controller
            control={control}
            name={'url'}
            rules={{
              required: t('links.add.form.url.errors.required'),
              pattern: {
                value: /^(ftp|http|https):\/\/[^ "]+$/,
                message: t('links.add.form.url.errors.invalid'),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                inputMode={'url'}
                sx={{ flex: 1 }}
                label={t('links.add.form.url.label')}
                placeholder={'https://'}
              />
            )}
          />
          <FormHelperText
            error
            sx={{
              maxWidth: 256,
            }}
          >
            {errors.url?.message}
          </FormHelperText>
          <LoadingButton
            variant={'contained'}
            type={'submit'}
            disabled={!isValid}
            loading={isLoading}
          >
            {t('sub-tasks.done.button.label')}
          </LoadingButton>
        </Box>
      </ListItem>
    </MUIDrawer>
  );
};

export default Drawer;
