import { Box, IconButton, Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  fontSize: number;
  variant: ComponentProps<typeof Typography>['variant'];
  vertical?: boolean;
  text?: boolean;
}

const Logo = ({ fontSize, variant, vertical = false, text = false }: Props) => {
  const navigate = useNavigate();

  return (
    <Box
      display={'flex'}
      flexDirection={vertical ? 'column' : 'row'}
      alignItems={'center'}
      gap={1}
    >
      <IconButton size={'medium'} onClick={() => navigate('/')}>
        <Box
          sx={{
            width: fontSize,
            height: fontSize,
          }}
          component={'img'}
          src={'/images/logo.svg'}
          alt={'Todochallenge Logo'}
        />
      </IconButton>
      {text && (
        <Typography variant={variant} fontSize={fontSize}>
          Todo
          <Box component={'span'}>challenge</Box>
        </Typography>
      )}
    </Box>
  );
};

export default Logo;
