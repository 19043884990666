import { RootState } from '@/app/redux/store/types';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state.links;

export const selectIsDrawerOpen = (
  key: keyof RootState['links']['isDrawerOpen'],
) => createSelector(selectSelf, (state) => state.isDrawerOpen[key]);

export const selectLinks = createSelector(selectSelf, (state) => state.links);

