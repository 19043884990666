import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { State } from "./types";

const initialState: State = {
  theme: 'light',
};

export const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSnackbar(state, action: PayloadAction<State['snackbar']>) {
      return {
        ...state,
        snackbar: action.payload,
      };
    },
    setTheme(state, action: PayloadAction<State['theme']>) {
      return {
        ...state,
        theme: action.payload,
      };
    },
  },
});

export const { setSnackbar, setTheme } = slice.actions;
export default slice.reducer;
