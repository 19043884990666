import { setIsDrawerOpen } from "@/app/features/home/slice";
import Icon from "@/app/icon";
import { useAppDispatch } from "@/app/redux/store/hooks";
import {
  AppBar,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Header = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerOpen = () => {
    dispatch(setIsDrawerOpen(true));
  };

  return (
    <AppBar
      position={"static"}
      sx={{
        bgcolor: "transparent",
        color: theme.palette.text.primary,
      }}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(!isMobile && { display: "none" }) }}
        >
          <Icon icon={"menu"} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
