import { useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import { Alert as IAlert } from '@/app/types';
import {
  Alert,
  Box,
  Container,
  Slide,
  SlideProps,
  Snackbar,
  useMediaQuery,
} from '@mui/material';
import Color from 'color';
import { useState } from 'react';
import { selectTheme } from '../ui/slice/selectors';
import Footer from './footer';
import Hero from './hero';
import Header from './menu';
import Statement from './statement';
import Steps from './steps';
import './style.css';
import Timeline from './timeline';

type TransitionProps = Omit<SlideProps, 'direction'>;

const Root = () => {
  const [alert, setAlert] = useState<IAlert | undefined>();
  const theme = useAppTheme();
  const mode = useAppSelector(selectTheme);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
  }

  const renderAlert = () => {
    return (
      <>
        {alert && (
          <Snackbar
            open={Boolean(alert)}
            TransitionComponent={TransitionDown}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              severity={alert.severity}
              onClose={() => setAlert(undefined)}
            >
              {alert.title}
            </Alert>
          </Snackbar>
        )}
      </>
    );
  };

  return (
    <Box height={'100vh'}>
      {renderAlert()}
      <Container maxWidth={'xl'}>
        <Header />
      </Container>
      <Container maxWidth={'lg'}>
        <Box mt={8}>
          <Hero />
        </Box>
      </Container>
      <Container
        maxWidth={'lg'}
        sx={{
          minHeight: { md: '100vh' },
          alignItems: { md: 'center' },
          display: 'flex',
          p: 2,
        }}
      >
        <Box
          sx={{
            outlineWidth: 2,
            outlineStyle: 'solid',
            outlineColor: Color(theme.palette.outline.variant)
              .alpha(0.32)
              .toString(),
          }}
          borderRadius={theme.shape.borderRadius}
          p={2}
          flex={1}
        >
          <div className="image-container">
            <Box component={'picture'}>
              <source
                srcSet={`/images/${
                  isMobile ? 'mobile' : 'desktop'
                }-preview-${mode}.webp`}
                type="image/webp"
              />
              <img
                src={`/images/${
                  isMobile ? 'mobile' : 'desktop'
                }-preview-${mode}.png`}
                alt={'Todochallenge screenshot preview'}
              />
            </Box>
          </div>
        </Box>
      </Container>
      <Statement />
      <Box my={8}>
        <Timeline />
      </Box>
      <Box my={8}>
        <Steps />
      </Box>
      <Container>
        <Box my={8}>
          <Footer />
        </Box>
      </Container>
    </Box>
  );
};

export default Root;
