import { supabase } from '@/app/config/supabase';
import { setSnackbar } from '@/app/features/ui/slice';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { removeTaskById, setSelectedTask } from '../../../slice';
import { selectSelectedTask } from '../../../slice/selectors';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ConfirmDeleteDialog = ({ isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.overview.confirm-delete-dialog',
  });
  const { t: commonT } = useTranslation();
  const selectedTask = useAppSelector(selectSelectedTask);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = async () => {
    if (!selectedTask) return;
    try {
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', selectedTask.id);
      if (error) throw new Error(error.message);
      dispatch(removeTaskById(selectedTask.id));
      dispatch(setSelectedTask());
      dispatch(
        setSnackbar({
          message: t('success.snackbar.message'),
          severity: 'success',
        }),
      );
    } catch (_) {
      dispatch(
        setSnackbar({
          message: commonT('general.snackbar.error.message'),
          severity: 'error',
        }),
      );
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans
            t={t}
            i18nKey={'description'}
            components={{
              bold: <b />,
            }}
            values={{
              value: selectedTask?.name,
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant={'outlined'}>
          {t('secondary.button.label')}
        </Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          variant={'contained'}
          color={'error'}
        >
          {t('primary.button.label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
