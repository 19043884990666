import { supabase } from '@/app/config/supabase';
import { Tables } from '@/app/db/types';
import Icon from '@/app/icon';
import { useAppDispatch } from '@/app/redux/store/hooks';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSnackbar } from '../../ui/slice';
import { removeLinkById } from '../slice';

const Link = ({ id, url }: Partial<Tables<'links'>>) => {
  const [isHover, setIsHover] = useState(false);
  const dispatch = useAppDispatch();
  const { t: commonT } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'links' });

  const handleOnMouseOver = () => {
    setIsHover(true);
  };

  const handleOnMouseLeave = () => {
    setIsHover(false);
  };

  const handleDelete = async () => {
    if (!id) return;
    try {
      const { error } = await supabase.from('links').delete().eq('id', id);
      if (error) throw new Error(error.message);
      dispatch(removeLinkById(id));
      dispatch(
        setSnackbar({
          message: t('delete.success.message'),
          severity: 'success',
        }),
      );
    } catch (_) {
      dispatch(
        setSnackbar({
          message: commonT('general.snackbar.error.message'),
          severity: 'error',
        }),
      );
    }
  };

  return (
    <ListItem
      disableGutters
      divider
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
      secondaryAction={
        <>
          {isHover && (
            <IconButton
              color={'error'}
              TouchRippleProps={{
                color: 'error',
              }}
              onClick={handleDelete}
            >
              <Icon icon={'trash'} />
            </IconButton>
          )}
        </>
      }
    >
      <ListItemButton onClick={() => window.open(url, '_blank')}>
        <ListItemText
          primary={url}
          sx={{
            wordWrap: 'break-word',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default Link;
