import { useAppTheme } from '@/app/theme';
import { Box, Container, Typography, colors } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

const Hero = () => {
  const theme = useAppTheme();
  const { t } = useTranslation();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} textAlign={'center'}>
      <Typography
        variant={'h2'}
        whiteSpace={'break-spaces'}
        fontWeight={theme.typography.fontWeightBold}
      >
        {t('hero.title')}
      </Typography>
      <Container maxWidth={'md'}>
        <Typography variant={'h5'} whiteSpace={'break-spaces'}>
          <Trans
            t={t}
            i18nKey={'hero.subtitle'}
            components={{
              bold: (
                <Typography
                  fontWeight={'bold'}
                  variant={'h5'}
                  sx={{
                    display: 'inline-block',
                    background: `linear-gradient(45deg, ${colors.red[300]}, ${colors.lightGreen[300]})`,
                    color: 'transparent',
                    WebkitBackgroundClip: 'text',
                  }}
                />
              ),
            }}
          />
        </Typography>
      </Container>
    </Box>
  );
};

export default Hero;
