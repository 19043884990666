import { supabase } from '@/app/config/supabase';
import { selectUser } from '@/app/features/auth/slice/selectors';
import {
  removeSubTaskById,
  setIsDrawerOpen,
  setSelectedSubTask,
  updateSubTask,
} from '@/app/features/home/sub-tasks/slice';
import {
  selectSelectedActivityDate,
  selectSelectedTask,
} from '@/app/features/home/tasks/slice/selectors';
import { setSnackbar } from '@/app/features/ui/slice';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  FormHelperText,
  IconButton,
  ListItem,
  Drawer as MUIDrawer,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatISO } from 'date-fns';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateActivity } from '../../../tasks/slice';
import {
  selectIsDrawerOpen,
  selectSelectedSubTask,
  selectSubTasks,
} from '../../slice/selectors';
import { FormValues } from '../../slice/types';
import { validateDate } from '../../slice/utils';

const Drawer = () => {
  const isOpen = useAppSelector(selectIsDrawerOpen('edit'));
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const user = useAppSelector(selectUser);
  const selectedTask = useAppSelector(selectSelectedTask);
  const selectedSubTask = useAppSelector(selectSelectedSubTask);
  const selectedActivityDate = useAppSelector(selectSelectedActivityDate);
  const subTasks = useAppSelector(selectSubTasks);
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    reset,
    control,
  } = useForm<FormValues>({
    values: selectedSubTask && {
      title: selectedSubTask.title,
      description: selectedSubTask.description ?? '',
      date: new Date(selectedSubTask.date),
    },
    mode: 'all',
  });
  const theme = useAppTheme();

  const handleOnClose = () => {
    dispatch(setSelectedSubTask(undefined));
    dispatch(setIsDrawerOpen({ key: 'edit', value: false }));
    reset();
  };

  const handleOnSubmit = async ({ title, description, date }: FormValues) => {
    if (!user || !selectedSubTask || !selectedTask) return;
    setIsLoading(true);
    try {
      const { data: updatedSubTask, error } = await supabase
        .from('sub_tasks')
        .update({
          title,
          description,
          date: date.toISOString(),
        })
        .match({ id: selectedSubTask.id })
        .select('*')
        .single();
      if (error) throw new Error(error.message);

      if (
        selectedActivityDate === formatISO(date, { representation: 'date' })
      ) {
        dispatch(updateSubTask(updatedSubTask));
      } else {
        dispatch(removeSubTaskById(selectedSubTask.id));
        if (subTasks) {
          const newSubTasks = subTasks?.filter(
            (item) => item.id !== selectedSubTask.id,
          );
          dispatch(
            updateActivity({
              subTasks: newSubTasks,
              date: selectedSubTask.date,
            }),
          );
          const { data: updatedSubTasks } = await supabase
            .from('sub_tasks')
            .select('*')
            .eq('task_id', selectedTask.id)
            .eq('date', updatedSubTask.date);
          if (updatedSubTasks) {
            dispatch(
              updateActivity({
                subTasks: updatedSubTasks,
                date: updatedSubTask.date,
              }),
            );
          }
        }
      }
      dispatch(setIsDrawerOpen({ key: 'edit', value: false }));
      reset();
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t('sub-tasks.edit.success.snackbar.message'),
        }),
      );
    } catch (e) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: t('general.snackbar.error.message'),
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!selectedTask) return <></>;

  return (
    <MUIDrawer
      variant={'temporary'}
      open={isOpen}
      anchor={'right'}
      onClose={handleOnClose}
      sx={{ width: 256 }}
    >
      <AppBar
        position={'static'}
        sx={{
          bgcolor: 'transparent',
          color: theme.palette.text.primary,
          pl: 0,
        }}
        elevation={0}
      >
        <Toolbar sx={{ gap: 2 }}>
          <IconButton onClick={handleOnClose}>
            <Icon icon={'close'} />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {t('sub-tasks.edit.title')}
          </Typography>
        </Toolbar>
      </AppBar>
      <ListItem>
        <Box
          component={'form'}
          display={'flex'}
          flexDirection={'column'}
          flex={1}
          gap={2}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <Controller
            control={control}
            name={'title'}
            rules={{ required: t('sub-tasks.form.title.error.required') }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ flex: 1 }}
                label={t('sub-tasks.form.title.label')}
              />
            )}
          />
          <FormHelperText
            error
            sx={{
              maxWidth: 256,
            }}
          >
            {errors.title?.message}
          </FormHelperText>
          <TextField
            {...register('description')}
            sx={{ flex: 1 }}
            label={t('sub-tasks.form.description.label')}
            multiline
            rows={3}
          />
          <Controller
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...field}
                  label={'Date'}
                  minDate={new Date(selectedTask.start_date)}
                  maxDate={new Date(selectedTask.end_date)}
                />
              </LocalizationProvider>
            )}
            name={'date'}
            rules={{
              validate: (value) =>
                validateDate(
                  value,
                  selectedTask.start_date,
                  selectedTask.end_date,
                ),
            }}
          />
          <FormHelperText
            error
            sx={{
              maxWidth: 256,
            }}
          >
            {errors.date?.message}
          </FormHelperText>
          <LoadingButton
            variant={'contained'}
            type={'submit'}
            disabled={!isValid}
            loading={isLoading}
          >
            {t('sub-tasks.done.button.label')}
          </LoadingButton>
        </Box>
      </ListItem>
    </MUIDrawer>
  );
};

export default Drawer;
