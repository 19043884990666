import { useAppSelector } from '@/app/redux/store/hooks';
import { Box, Container, Typography, colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectTheme } from '../../ui/slice/selectors';

const Timeline = () => {
  const theme = useAppSelector(selectTheme);
  const { t } = useTranslation('translation', { keyPrefix: 'timeline' });

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Container maxWidth={'lg'}>
        <Box textAlign={'center'} display={'flex'} flexDirection={'column'}>
          <Box
            display={'flex'}
            textAlign={'center'}
            gap={2}
            justifyContent={'center'}
            flexDirection={'column'}
            p={4}
          >
            <Typography
              variant={'displaySmall'}
              fontWeight={'bold'}
              sx={{
                display: 'inline-block',
                background: `linear-gradient(45deg, ${colors.red[300]}, ${colors.lightGreen[300]})`,
                color: 'transparent',
                WebkitBackgroundClip: 'text',
              }}
            >
              {t('title')}
            </Typography>
            <Typography variant={'body1'} fontWeight={'bold'}>
              {t('subtitle')}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Box component={'picture'}>
              <source
                srcSet={`/images/chart-${theme}.webp`}
                type="image/webp"
              />
              <Box
                component={'img'}
                src={`/images/chart-${theme}.png`}
                maxWidth={'80%'}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Timeline;
