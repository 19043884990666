import { useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import {
  Box,
  CardContent,
  CircularProgress,
  CircularProgressProps,
  Card as MUICard,
  Typography,
  circularProgressClasses,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectActivities, selectSelectedTask } from '../../../slice/selectors';
import { calculateTimelineCompletionPercentage as calculatePercentage } from './utils';

function CustomCircularProgress(props: CircularProgressProps) {
  const activities = useAppSelector(selectActivities);
  const task = useAppSelector(selectSelectedTask);
  const percentage =
    task && calculatePercentage(task.start_date, task.end_date);

  if (!activities) return <></>;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant={'determinate'}
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 300 : 500],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant={'determinate'}
        disableShrink
        sx={{
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" component="div" color="text.secondary">
          {percentage === undefined ? '' : `${percentage}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const Card = () => {
  const activities = useAppSelector(selectActivities);
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.overview.statistics.timeline-completion',
  });
  const selectedTask = useAppSelector(selectSelectedTask);
  const percentage =
    selectedTask &&
    calculatePercentage(selectedTask.start_date, selectedTask.end_date);
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!activities) return <></>;

  return (
    <MUICard variant={'outlined'} sx={{ ...(isMobile && { flex: 1 }) }}>
      <Box p={1}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box textAlign={'center'}>
            <Typography variant="body1">{t('title')}</Typography>
          </Box>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
            }}
          >
            <CustomCircularProgress size={64} value={percentage} />
          </Box>
        </CardContent>
      </Box>
    </MUICard>
  );
};

export default Card;
