import { Tables } from '@/app/db/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from './types';

const initialState: State = {
  isDrawerOpen: {
    add: false,
    edit: false,
  },
};

export const slice = createSlice({
  name: 'sub-tasks',
  initialState,
  reducers: {
    setIsDrawerOpen(
      state,
      action: PayloadAction<{
        key: keyof State['isDrawerOpen'];
        value: boolean;
      }>,
    ) {
      return {
        ...state,
        isDrawerOpen: {
          ...state.isDrawerOpen,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setSelectedSubTask(state, action: PayloadAction<State['selectedSubTask']>) {
      return {
        ...state,
        selectedSubTask: action.payload,
      };
    },
    setSubTasks(state, action: PayloadAction<State['subTasks']>) {
      return {
        ...state,
        subTasks: action.payload,
      };
    },
    removeSubTaskById(state, action: PayloadAction<Tables<'tasks'>['id']>) {
      return {
        ...state,
        subTasks: state.subTasks?.filter((item) => item.id !== action.payload),
      };
    },
    updateSubTask(state, action: PayloadAction<Tables<'sub_tasks'>>) {
      const updatedSubTask = action.payload;

      return {
        ...state,
        subTasks: state.subTasks?.map((subTask) =>
          subTask.id === updatedSubTask.id ? updatedSubTask : subTask,
        ),
      };
    },
  },
});

export const {
  setIsDrawerOpen,
  setSelectedSubTask,
  setSubTasks,
  removeSubTaskById,
  updateSubTask,
} = slice.actions;
export default slice.reducer;
