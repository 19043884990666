import { selectTheme } from '@/app/features/ui/slice/selectors';
import { useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import { Box, Typography, colors, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Legend = () => {
  const appTheme = useAppTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down('md'));
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.overview.activities.legend',
  });
  const theme = useAppSelector(selectTheme);

  return (
    <Box display={'flex'} gap={2} flexDirection={isMobile ? 'column' : 'row'}>
      <Box display={'flex'} gap={1}>
        <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
          <rect
            width={16}
            height={16}
            fill={theme === 'light' ? colors.grey[300] : colors.grey[500]}
          />
        </svg>
        <Typography>{t('level-0')}</Typography>
      </Box>
      <Box display={'flex'} gap={1}>
        <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
          <polygon points="0,0 0,100 100,100" fill={colors.red[300]} />
        </svg>
        <Typography>{t('level-1')}</Typography>
      </Box>
      <Box display={'flex'} gap={1}>
        <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
          <rect width={16} height={16} fill={colors.green[300]} />
        </svg>
        <Typography>{t('level-2')}</Typography>
      </Box>
    </Box>
  );
};

export default Legend;
