import { Tables } from '@/app/db/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from './types';

const initialState: State = {
  isDrawerOpen: {
    add: false,
  },
};

export const slice = createSlice({
  name: 'links',
  initialState,
  reducers: {
    setIsDrawerOpen(
      state,
      action: PayloadAction<{
        key: keyof State['isDrawerOpen'];
        value: boolean;
      }>,
    ) {
      return {
        ...state,
        isDrawerOpen: {
          ...state.isDrawerOpen,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setLinks(state, action: PayloadAction<State['links']>) {
      return {
        ...state,
        links: action.payload,
      };
    },
    removeLinkById(state, action: PayloadAction<Tables<'links'>['id']>) {
      return {
        ...state,
        links: state.links?.filter((item) => item.id !== action.payload),
      };
    },
  },
});

export const { setIsDrawerOpen, setLinks, removeLinkById } = slice.actions;
export default slice.reducer;
