import Logo from '@/app/components/logo';
import { supabase } from '@/app/config/supabase';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import {
  AppBar,
  Box,
  Button,
  ListItem,
  ListItemText,
  Drawer as MUIDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { selectUser } from '../../auth/slice/selectors';
import { setIsDrawerOpen } from '../slice';
import { selectIsDrawerOpen } from '../slice/selectors';
import Task from '../tasks/components/task';
import {
  setIsDrawerOpen as setTaskIsDrawerOpen,
  setTasks,
} from '../tasks/slice';
import { selectTasks } from '../tasks/slice/selectors';
import Menu from './components/menu';

const Drawer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tasks = useAppSelector(selectTasks);
  const user = useAppSelector(selectUser);
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);

  useEffect(() => {
    (async () => {
      try {
        const { data, error } = await supabase
          .from('tasks')
          .select('*')
          .order('created_at', { ascending: false });
        if (error) throw new Error(error.message);
        dispatch(setTasks(data));
      } catch (e) {
        if (e instanceof Error) console.error(e.message);
      }
    })();
  }, [dispatch]);

  const handleAddTaskClick = async () => {
    if (!user) return;
    dispatch(setTaskIsDrawerOpen({ key: 'add', value: true }));
  };

  const handleClose = () => {
    dispatch(setIsDrawerOpen(false));
  };

  return (
    <MUIDrawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isDrawerOpen}
      onClose={handleClose}
      PaperProps={{ style: { position: 'absolute' } }}
      ModalProps={{
        container: document.getElementById('drawer-container'),
        style: { position: 'absolute' },
      }}
    >
      <Box
        display={'flex'}
        height={'100vh'}
        width={256}
        flexDirection={'column'}
      >
        <AppBar
          position={'relative'}
          sx={{
            bgcolor: 'transparent',
            color: theme.palette.text.primary,
          }}
          elevation={0}
        >
          <ListItem sx={{ gap: 1 }}>
            <Logo
              variant={'h6'}
              fontSize={theme.typography.h6.fontSize as number}
              text
            />
          </ListItem>
        </AppBar>
        <Menu />
        <ListItem>
          <ListItemText>
            <Typography variant={'subtitle1'} color={'text.secondary'}>
              {t('tasks.title')}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <Button
            variant={'contained'}
            sx={{ flex: 1 }}
            onClick={handleAddTaskClick}
            startIcon={<Icon icon={'add'} />}
          >
            {t('tasks.add.button.label')}
          </Button>
        </ListItem>
        <Box overflow={'auto'}>
          {tasks?.map((item) => (
            <Task key={item.id} {...item} />
          ))}
        </Box>
      </Box>
    </MUIDrawer>
  );
};

export default Drawer;
