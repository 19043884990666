import { useAppDispatch, useAppSelector } from "@/app/redux/store/hooks";
import { Alert, Snackbar as MUISnackbar } from "@mui/material";
import { setSnackbar } from "../slice";
import { AUTO_HIDE_DURATION_SHORT } from "../slice/constants";
import { selectSnackbar } from "../slice/selectors";

const Snackbar = () => {
  const dispatch = useAppDispatch();
  const snackbar = useAppSelector(selectSnackbar);

  const handleOnClose = () => {
    dispatch(setSnackbar());
  };

  if (!snackbar) return <></>;

  return (
    <MUISnackbar
      open={true}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={AUTO_HIDE_DURATION_SHORT}
      onClose={handleOnClose}
    >
      <Alert
        severity={snackbar.severity}
        variant={"filled"}
        onClose={handleOnClose}
      >
        {snackbar.message}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
