import authReducer from "@/app/features/auth/slice";
import { State as AuthState } from '@/app/features/auth/slice/types';
import homeReducer from '@/app/features/home/slice';
import subTasksReducer from '@/app/features/home/sub-tasks/slice';
import tasksReducer from '@/app/features/home/tasks/slice';
import { State as TasksState } from '@/app/features/home/tasks/slice/types';
import linksReducer from '@/app/features/links/slice';
import uiReducer from '@/app/features/ui/slice';
import { State as UIState } from '@/app/features/ui/slice/types';
import { Middleware, combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  PersistConfig,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const middlewares: Middleware[] = [];

const persistAuthConfig: PersistConfig<AuthState> = {
  key: 'auth',
  storage,
};

const persistUIConfig: PersistConfig<UIState> = {
  key: 'ui',
  storage,
  whitelist: ['theme'],
};

const persistTasksConfig: PersistConfig<TasksState> = {
  key: 'tasks',
  storage,
  whitelist: ['isHideLegend'],
};

const rootReducer = combineReducers({
  auth: persistReducer(persistAuthConfig, authReducer),
  tasks: persistReducer(persistTasksConfig, tasksReducer),
  ui: persistReducer(persistUIConfig, uiReducer),
  'sub-tasks': subTasksReducer,
  home: homeReducer,
  links: linksReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewares),
});

export const persistor = persistStore(store);

