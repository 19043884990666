import { Tables } from '@/app/db/types';
import { Level } from 'react-activity-calendar';

export function calculateActivityLevel(
  activitySubTasks: Tables<'sub_tasks'>[],
): Level {
  if (activitySubTasks.length > 0) {
    if (activitySubTasks.every((subTask) => subTask.done)) {
      return 2;
    }

    if (activitySubTasks.some((subTask) => !subTask.done)) {
      return 1;
    }
  }
  return 0;
}
