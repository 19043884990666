import Home from '@/app/features/home/page';
import Joined from '@/app/features/root/joined/page';
import Root from '@/app/features/root/page';
import Privacy from '@/app/features/root/policies/privacy/page';
import TermsAndConditions from '@/app/features/root/policies/terms-and-conditions/page';
import Verified from '@/app/features/root/verified/page';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { User } from '@supabase/supabase-js';
import { differenceInMinutes, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { supabase } from './config/supabase';
import Auth from './features/auth';
import { setUser } from './features/auth/slice';
import { selectUser } from './features/auth/slice/selectors';
import { selectTheme } from './features/ui/slice/selectors';
import { useAppDispatch, useAppSelector } from './redux/store/hooks';
import { darkTheme, lightTheme } from './theme';

const App = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const theme = useAppSelector(selectTheme);

  const addUserToNewsletter = (email: User['email']) => {
    fetch(
      `https://api.convertkit.com/v3/forms/${process.env.REACT_APP_CONVERTKIT_FORM_ID}/subscribe?`,
      {
        body: JSON.stringify({
          email,
          api_key: process.env.REACT_APP_CONVERTKIT_API_KEY,
        }),
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        method: 'POST',
      },
    );
  };

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      dispatch(setUser(session?.user));
      (async () => {
        if (session && event === 'SIGNED_IN') {
          if (session.user.last_sign_in_at) {
            const timeDiff = differenceInMinutes(
              parseISO(session.user.last_sign_in_at),
              parseISO(session.user.created_at),
            );
            if (timeDiff < 1) {
              addUserToNewsletter(session.user.email);
            }
          }
        }
      })();
    });
  }, [dispatch]);

  const routes = [
    {
      path: '/',
      element: <Root />,
    },
    {
      path: '/joined',
      element: <Joined />,
    },
    {
      path: '/verified',
      element: <Verified />,
    },
    {
      path: '/policies/terms-and-conditions',
      element: <TermsAndConditions />,
    },
    {
      path: '/policies/privacy',
      element: <Privacy />,
    },
    {
      path: '/home',
      element: user ? <Home /> : <Navigate to={'/auth'} />,
    },
    {
      path: '/auth',
      element: !user ? <Auth /> : <Navigate to={'/home'} />,
    },
  ];

  const getTheme = () => {
    switch (theme) {
      case 'light':
        return lightTheme;
      case 'dark':
        return darkTheme;
      default:
        return lightTheme;
    }
  };

  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
