import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Footer from "../../footer";
import Header from "../../menu";

const Page = () => {
  const items = [
    {
      title: "Acceptance of Terms",
      description:
        "By accessing or using Todochallenge, you agree to be bound by these Terms and Conditions, our Privacy Policy, and all applicable laws and regulations. If you do not agree to these terms, please do not use our Service.",
    },
    {
      title: "User Registration",
      description:
        "To use certain features of the Service, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
    },
    {
      title: "User Conduct",
      description:
        "You agree to use Todochallenge in accordance with all applicable laws and regulations. You are solely responsible for all content, data, and activity that occurs under your account.",
    },
    {
      title: "Legal",
      description: (
        <span>
          Your use of Todochallenge is also governed by our{" "}
          <Link href={"/policies/privacy"} target="_blank">
            Privacy Policy
          </Link>
          . By using the Service, you consent to the terms of our Privacy
          Policy.
        </span>
      ),
    },
    {
      title: "Intellectual Property",
      description:
        "Todochallenge and its original content, features, and functionality are owned by Todochallenge and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.",
    },
    {
      title: "Termination",
      description:
        "We reserve the right to terminate or suspend your account and access to the Service at our sole discretion, without notice, for any reason, including but not limited to a breach of these Terms and Conditions.",
    },
    {
      title: "Changes to Terms",
      description:
        "We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Your continued use of Todochallenge after any changes to these Terms and Conditions constitutes acceptance of those changes.",
    },
    {
      title: "Pricing",
      subItems: [
        {
          title: "Subscription Plans",
          description:
            "Todochallenge offers flexible subscription plans to meet the diverse needs of our users. We provide both lifetime and monthly subscription options, allowing you to choose the plan that best aligns with your preferences and usage requirements.",
        },
        {
          title: "Lifetime Subscription",
          description:
            "The Lifetime Subscription is a one-time payment that grants you unlimited access to Todochallenge for the duration of the service's existence. This is a one-time investment, providing you with continuous benefits without the need for recurring monthly payments. Enjoy the full suite of Todochallenge features without worrying about subscription renewals.",
        },
        {
          title: "Monthly Subscription",
          description:
            "For users who prefer a more flexible payment structure, Todochallenge offers a Monthly Subscription option. With this plan, you will be billed on a monthly basis, allowing you to pay as you go. This subscription is ideal for those who may want to evaluate the service over a shorter period or who prefer the convenience of monthly billing.",
        },
        {
          title: "Payment Processing",
          description:
            "All transactions are securely processed through Stripe, a trusted and reputable payment gateway. Stripe ensures the confidentiality and security of your payment information, providing a seamless and reliable payment experience.",
        },
        {
          title: "Pricing Changes",
          description:
            "Todochallenge reserves the right to modify subscription plans, pricing, or payment terms at any time. Changes to pricing will be communicated to users in advance, and users will have the option to continue with their existing plan or make adjustments based on the updated pricing structure.",
        },
        {
          title: "Refund Policy",
          description: (
            <span>
              If you are not satisfied with the Product, you may request a
              refund within the first 14 days of purchase. Refund requests can
              be made by contacting our customer support at{" "}
              <Link href="mailto:hello@todochallenge.app">
                hello@todochallenge.app
              </Link>
              . We will make reasonable efforts to address your concerns and
              process refund requests promptly.
            </span>
          ),
        },
      ],
    },
    {
      title: "Governing Law",
      description:
        "These Terms and Conditions are governed by and construed in accordance with the laws of Australia, without regard to its conflict of law principles.",
    },
    {
      title: "Customer Support",
      description: (
        <span>
          For customer support inquiries, please contact us at{" "}
          <Link href="mailto:hello@todochallenge.app" target="_blank">
            hello@todochallenge.app
          </Link>
          . We will make reasonable efforts to address and resolve any issues
          promptly.
        </span>
      ),
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box display={"flex"} gap={2} flexDirection={"column"} py={2}>
        <Header />
        <Box
          display={"flex"}
          gap={2}
          flexDirection={"column"}
          textAlign={"center"}
        >
          <Typography variant={"h3"} fontWeight={"bold"}>
            Terms and Conditions
          </Typography>
          <Typography variant={"h4"}>
            <span style={{ fontWeight: "bold" }}>Last Updated:</span> 11th
            December 2023
          </Typography>
          <Typography variant={"body1"}>
            Welcome to Todochallenge ("we," "us," or "our"). Please read these
            terms and conditions carefully before using our web-based software
            as a service (SaaS). By accessing or using Todochallenge (the
            "Service"), you agree to comply with and be bound by the following
            terms and conditions:
          </Typography>
        </Box>
        <List>
          {items.map((item, index) => (
            <ListItem
              key={item.title}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <ListItemText
                primary={`${index + 1}. ${item.title}`}
                secondary={item.description}
                primaryTypographyProps={{
                  fontWeight: "bold",
                }}
              />
              {item.subItems?.map((subItem, subIndex) => (
                <ListItem key={subItem.title}>
                  <ListItemText
                    primary={`${index + 1}.${subIndex}. ${subItem.title}`}
                    secondary={subItem.description}
                    primaryTypographyProps={{
                      fontWeight: "bold",
                    }}
                  />
                </ListItem>
              ))}
            </ListItem>
          ))}
        </List>
        <Footer />
      </Box>
    </Container>
  );
};

export default Page;
