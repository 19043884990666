import { useAppSelector } from '@/app/redux/store/hooks';
import { Box, Container, Paper, Typography, colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectTheme } from '../../ui/slice/selectors';

const Statement = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'statement' });
  const theme = useAppSelector(selectTheme);

  return (
    <Paper
      sx={{
        height: '25vh',
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none',
        bgcolor: theme === 'light' ? colors.grey[100] : '',
      }}
    >
      <Container maxWidth={'lg'}>
        <Box
          display={'flex'}
          textAlign={'center'}
          gap={2}
          flexDirection={'column'}
        >
          <Typography variant={'displaySmall'} fontWeight={'bold'}>
            {t('title')}
          </Typography>
          <Typography variant={'body1'} fontWeight={'bold'}>
            {t('subtitle')}
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default Statement;
