import { Box, Container } from "@mui/material";
import { useAppSelector } from "../../redux/store/hooks";
import Snackbar from "../ui/components/snackbar";
import { selectSnackbar } from '../ui/slice/selectors';
import Drawer from './drawer';
import None from './none';
import AddDrawer from './tasks/add/components/drawer';
import EditDrawer from './tasks/edit/components/drawer';
import Overview from './tasks/overview';
import { selectSelectedTask } from './tasks/slice/selectors';

const Home = () => {
  const snackbar = useAppSelector(selectSnackbar);
  const selectedTask = useAppSelector(selectSelectedTask);

  return (
    <Box
      minHeight={'100vh'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Container
        id={'drawer-container'}
        sx={{ position: 'absolute', height: '100%' }}
      >
        {snackbar && <Snackbar />}
        <Drawer />
        {selectedTask ? <Overview key={selectedTask.id} /> : <None />}
        <AddDrawer />
        <EditDrawer key={JSON.stringify(selectedTask)} />
      </Container>
    </Box>
  );
};

export default Home;
