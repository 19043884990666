import { isAfter, isBefore, startOfDay } from 'date-fns';
import { t } from 'i18next';
import { FormValues } from './slice/types';

export const validateStartDate = (
  endDate: FormValues['endDate'],
  startDate: FormValues['startDate'],
) => {
  const currentDate = new Date();
  if (isBefore(startOfDay(startDate), startOfDay(currentDate))) {
    return t('tasks.form.start-date.error.min');
  }
  if (isAfter(startOfDay(startDate), startOfDay(endDate))) {
    return t('tasks.form.start-date.error.max');
  }
  return true;
};

export const validateEndDate = (
  startDate: FormValues['startDate'],
  endDate: FormValues['endDate'],
) => {
  if (isBefore(startOfDay(endDate), startOfDay(startDate))) {
    return t('tasks.form.end-date.error.min');
  }

  return true;
};
