import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { State } from "./types";

const initialState: State = {};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<State['token']>) {
      return {
        ...state,
        token: action.payload,
      };
    },
    setUser(state, action: PayloadAction<State['user']>) {
      return {
        ...state,
        user: action.payload,
      };
    },
  },
});

export const { setToken, setUser } = slice.actions;

export default slice.reducer;
