import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import { useAppTheme } from '@/app/theme';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { setIsDrawerOpen } from '../../../../slice';
import { setIsDrawerOpen as setTaskIsDrawerOpen } from '../../../slice';
import { selectSelectedTask } from '../../../slice/selectors';
import ConfirmDeleteDialog from './confirm-delete-dialog';

const Header = () => {
  const dispatch = useAppDispatch();
  const selectedTask = useAppSelector(selectSelectedTask);
  const theme = useAppTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const handleConfirmDelete = async () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const handleEdit = () => {
    dispatch(setTaskIsDrawerOpen({ key: 'edit', value: true }));
  };

  const handleDrawerOpen = () => {
    dispatch(setIsDrawerOpen(true));
  };

  return (
    <AppBar
      position={'static'}
      sx={{
        bgcolor: 'transparent',
        color: theme.palette.text.primary,
      }}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(!isMobile && { display: 'none' }) }}
        >
          <Icon icon={'menu'} />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {selectedTask?.name ?? ''}
        </Typography>
        <IconButton onClick={handleEdit}>
          <Icon icon={'edit-outlined'} />
        </IconButton>
        <>
          <ConfirmDeleteDialog
            isOpen={isConfirmDeleteDialogOpen}
            setIsOpen={setIsConfirmDeleteDialogOpen}
          />
          <IconButton
            color={'error'}
            TouchRippleProps={{
              color: 'error',
            }}
            onClick={handleConfirmDelete}
          >
            <Icon icon={'trash'} />
          </IconButton>
        </>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
