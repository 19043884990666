import { normaliseKey } from '@/app/utils/i18n';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../../slice/types';

const DurationInput = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'tasks.form.duration',
  });
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<FormValues>();
  const interval = watch('interval');
  const suffix = t(normaliseKey(`suffix.${interval}`));

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputValue = event.target.value;
    const nonNegativeValue = Math.max(0, parseInt(inputValue, 10)) || 1;
    event.target.value = String(nonNegativeValue);
  };

  return (
    <FormControl fullWidth>
      <TextField
        {...register('duration', {
          required: t('errors.required'),
          pattern: {
            value: /^\d+$/,
            message: t('errors.invalid'),
          },
        })}
        label={t('label')}
        type={'number'}
        error={Boolean(errors.duration)}
        InputProps={{
          inputProps: { min: 1 },
          endAdornment: (
            <InputAdornment position={'end'}>{`/ ${suffix}`}</InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleOnChange}
      />
      <FormHelperText error>{errors.duration?.message}</FormHelperText>
    </FormControl>
  );
};

export default DurationInput;
