import { Tables } from '@/app/db/types';
import Icon from '@/app/icon';
import { useAppDispatch, useAppSelector } from '@/app/redux/store/hooks';
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { setSelectedTask } from '../slice';
import { selectSelectedTask } from '../slice/selectors';

const Task = (task: Tables<'tasks'>) => {
  const dispatch = useAppDispatch();
  const selectedTask = useAppSelector(selectSelectedTask);
  const isActive = task.id === selectedTask?.id;

  const handleOnClick = () => {
    dispatch(setSelectedTask(task));
  };

  return (
    <ListItem>
      <ListItemButton onClick={handleOnClick} selected={isActive}>
        <ListItemAvatar>
          <IconButton>
            <Icon icon={'hashtag'} />
          </IconButton>
        </ListItemAvatar>
        <ListItemText primary={task.name} />
      </ListItemButton>
    </ListItem>
  );
};

export default Task;
