import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { State } from "./types";

const initialState: State = {
  isDrawerOpen: false,
};

export const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setIsDrawerOpen(state, action: PayloadAction<State["isDrawerOpen"]>) {
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    },
  },
});

export const { setIsDrawerOpen } = slice.actions;
export default slice.reducer;
