import Logo from '@/app/components/logo';
import { analytics } from '@/app/config/firebase';
import { supabase } from '@/app/config/supabase';
import Icon from '@/app/icon';
import { Events } from '@/app/utils/firebase/enums';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { Trans, useTranslation } from 'react-i18next';

const Auth = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleGoogleOnClick = async () => {
    if (process.env.NODE_ENV !== 'development') {
      logEvent(analytics, Events.AUTH_SIGN_IN_GOOGLE);
    }
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${process.env.REACT_APP_SITE_DOMAIN}/home`,
        },
      });
      if (error) throw new Error(error.message);
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
      }
    }
  };

  return (
    <Container maxWidth={'xs'}>
      <Box
        minHeight={'100vh'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
        gap={4}
        paddingBottom={'48px'}
      >
        <Logo
          variant={'h4'}
          fontSize={theme.typography.h4.fontSize as number}
          text
        />
        <Box
          bgcolor={theme.palette.primary.main}
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          textAlign={'center'}
          p={4}
          gap={2}
          borderRadius={theme.shape.borderRadius}
          color={'secondary.main'}
        >
          <Typography variant={'h6'}>{t('auth.title')}</Typography>
          <Button
            onClick={handleGoogleOnClick}
            startIcon={
              <Box
                component={'img'}
                src={'/images/google.svg'}
                alt={'Google logo'}
              />
            }
            sx={{ py: 1 }}
            variant={'outlined'}
            color={'secondary'}
          >
            {t('auth.continue-google')}
          </Button>
          <Box
            display={'flex'}
            flexDirection={'row'}
            textAlign={'start'}
            gap={1}
          >
            <Icon icon={'check-circle'} />
            <Typography variant={'body1'}>
              <Trans
                i18nKey={'auth.terms-and-conditions'}
                components={{
                  terms: (
                    <Link
                      href={'/policies/terms-and-conditions'}
                      style={{
                        color: theme.palette.secondary.main,
                        textDecoration: 'underline',
                      }}
                      target={'_blank'}
                    />
                  ),
                  privacy: (
                    <Link
                      href={'/policies/privacy'}
                      style={{
                        color: theme.palette.secondary.main,
                        textDecoration: 'underline',
                      }}
                      target={'_blank'}
                    />
                  ),
                }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Auth;
