import { isAfter, isBefore, startOfDay } from 'date-fns';
import { t } from 'i18next';

export const validateDate = (
  date: Date,
  startDate: string,
  endDate: string,
) => {
  const parsedStartDate = startOfDay(new Date(startDate));
  const parsedEndDate = startOfDay(new Date(endDate));

  if (isBefore(date, parsedStartDate) || isAfter(date, parsedEndDate)) {
    return t('sub-tasks.form.date.errors.out-of-range');
  }

  return true;
};
