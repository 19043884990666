import en from "@/app/locales/en/translation.json";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: en,
  },
};

export const i18n = i18next.use(initReactI18next).init({
  resources: resources,
  fallbackLng: 'en',
});
